import { Abbreviations, Units } from '../../constants/units-and-abbreviations';
import { CarDetails } from '../../lib/api/models/hessel-api/hire';
import { formatDistance } from './distance.helper';
import { formatPrice } from './price.helper';
import { isNullOrEmpty } from './text-convert.helpers';

export function getVanOperationalLeasingDisclaimer(
    consumption: string,
    co2Emission: string,
    energyLabel: string,
    pricePerMonth: number,
    downPayment: number,
    durationMonths: number,
    kilometersPerYear: number,
    establishmentFee: number,
    pantOwnerDeclaration: number,
    greenOwnerFee: number,
    isAFromPrice: boolean
): string {
    return `Forbrug ved blandet kørsel: ${consumption}, CO2 udledning: ${co2Emission}${
        energyLabel && energyLabel.length > 0 ? ` , energimærke: ${energyLabel}` : ''
    }. Pris pr. md.: ${isAFromPrice ? 'fra ' : ''}${formatPrice(pricePerMonth)} kr., førstegangsydelse/udbetaling: ${formatPrice(
        downPayment
    )} kr., løbetid: ${durationMonths} mdr. / ${formatDistance(kilometersPerYear ?? 0)} ${Units.km}, etableringsgebyr: ${formatPrice(
        establishmentFee
    )} kr., panthaverdeklaration: ${formatPrice(pantOwnerDeclaration)} kr. halvårlig grøn ejerafgift: ${formatPrice(
        greenOwnerFee
    )} kr. Alle priser er vejledende ekskl. moms, og kan variere. Aftale forudsætter positiv kreditgodkendelse. Der tages forbehold for fejl samt at biler kan være vist med ekstraudstyr.`;
}

export function getVanFinancialLeasingDisclaimer(
    consumption: string,
    co2Emission: string,
    energyLabel: string,
    baseMonthlyPrice: number,
    downPayment: number,
    durationMonths: number,
    kilometersPerYear: number,
    greenOwnershipFee: number,
    residualValue: number,
    isAFromPrice: boolean
): string {
    return `Forbrug ved blandet kørsel: ${consumption}, CO2 udledning: ${co2Emission}${
        energyLabel && energyLabel.length > 0 ? `, energimærke: ${energyLabel}` : ''
    }. Pris pr. md.: ${isAFromPrice ? 'fra ' : ''}${formatPrice(baseMonthlyPrice)} kr., førstegangsydelse/udbetaling: ${formatPrice(
        downPayment
    )} kr., løbetid: ${durationMonths} mdr. / ${formatDistance(kilometersPerYear ?? 0)} ${Units.km}, halvårlig grøn ejerafgift: ${formatPrice(
        greenOwnershipFee
    )} kr. Ved leasingsaftalens ordinære udløb skal leasingtager anvise køber til restværdi på ${formatPrice(
        residualValue
    )} kr. ekskl. moms og afgift. Priserne er vejledende ekskl. moms og kan variere. Aftale forudsætter positiv kreditgodkendelse. Der tages forbehold for fejl samt at biler kan være vist med ekstraudstyr.`;
}

export function getCarPrivateLeasingDisclaimer(
    consumption: string,
    co2Emission: string,
    energyLabel: string,
    baseMonthlyPrice: number,
    downPayment: number,
    establishmentFee: number,
    totalCreditCostIncludingSetUpCost: number,
    durationMonths: number,
    kilometersPerYear: number,
    isAFromPrice: boolean,
    leasingPriceIncludesExtraBenefits: boolean
): string {
    return `Forbrug ved blandet kørsel: ${consumption}, CO2 udledning: ${co2Emission}${
        !isNullOrEmpty(energyLabel) ? `, energimærke: ${energyLabel}` : ''
    }. Pris pr. md.: ${isAFromPrice ? 'fra ' : ''} ${formatPrice(baseMonthlyPrice)} ${Abbreviations.KR}, førstegangsydelse/udbetaling: ${formatPrice(
        downPayment
    )} kr., etableringsomkostninger: ${formatPrice(establishmentFee)} ${Abbreviations.KR}, totalomkostninger i perioden: ${formatPrice(
        totalCreditCostIncludingSetUpCost
    )} kr., løbetid: ${durationMonths} ${Abbreviations.MDR}/${formatPrice(kilometersPerYear)} ${Units.km} pr. år. Priser er inkl. ${
        leasingPriceIncludesExtraBenefits
            ? 'service, forsikring og ekskl. grøn ejerafgift, dæk og forbrugsvæsker'
            : 'service og ekskl. forsikring, grøn ejerafgift, dæk og forbrugsvæsker'
    }. Aftale forudsætter positiv kreditgodkendelse. Der tages forbehold for fejl samt at biler kan være vist med ekstraudstyr.`;
}

//EJH-3927 temp solution, more details in jira task
export const campaignIdForExtraPrivateLeasingBenefits = 'PrivateLeasingPriceIncludesGreenFeeAndInsurance';
export const privateLeasingHasExtraBenefits = (vehicle: CarDetails): boolean => {
    return vehicle.campaigns.some((x) => x.text === campaignIdForExtraPrivateLeasingBenefits);
};
