import { APIResponse } from '..';
import { VehicleApiVersion } from '../../../constants/api-const';
import { getAPI } from '../helpers';
import { hesselApiTypes } from '../models/hessel-api';

const Vehicle_BASE_API_URL = `api/${VehicleApiVersion}`;

export const getCarDetails = async (params: { id: string }): APIResponse<hesselApiTypes.ProductDetails> => {
    return getAPI<hesselApiTypes.ProductDetails>(`${Vehicle_BASE_API_URL}/vehicles`, params);
};

export const getVehicleFamily = async (familyId: string): APIResponse<Array<hesselApiTypes.CarDetails>> => {
    return getAPI<Array<hesselApiTypes.CarDetails>>(`${Vehicle_BASE_API_URL}/vehicles/family/${familyId}`);
};

export const getVehicle = async (carUniqueId: string): APIResponse<Array<hesselApiTypes.CarDetails>> => {
    return getAPI<Array<hesselApiTypes.CarDetails>>(`${Vehicle_BASE_API_URL}/vehicles/${carUniqueId}`);
};

export const getCarByIds = async (ids: Array<string>): APIResponse<Array<hesselApiTypes.CarDetails>> => {
    return getAPI<Array<hesselApiTypes.CarDetails>>(`${Vehicle_BASE_API_URL}/vehicles`, {
        id: ids,
    });
};
