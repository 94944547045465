import { fetchContentAPI, getAPI, postAPI } from './helpers';
import { umbraco } from './models';
import { APIResponse } from './types';
import { bookingConsts } from '../../constants';
import { FormPostRequest } from './models/umbraco';

const SITE_ID = process.env.NEXT_PUBLIC_SITE_ID;

const BASE_CONTENT_URL = `api/${bookingConsts.ContentVersion}`;

export const getFeatureToggles = async (): APIResponse<string[]> => {
    return getAPI<string[]>(`api/v2/featureflags/enabled`);
};

export const getSitemap = async (): APIResponse<unknown> => {
    return fetchContentAPI(`${BASE_CONTENT_URL}/sitemap`);
};

export const getSiteSettings = async (): APIResponse<umbraco.SiteSettings> => {
    return fetchContentAPI<umbraco.SiteSettings>(`${BASE_CONTENT_URL}/settings/${SITE_ID}`);
};

export const getSiteSettingsById = async (siteId: string): APIResponse<umbraco.SiteSettings> => {
    return fetchContentAPI<umbraco.SiteSettings>(`${BASE_CONTENT_URL}/settings/${siteId}`);
};

export const getPage = async (url = ''): APIResponse<umbraco.Page> => {
    return fetchContentAPI<umbraco.Page>(`${SITE_ID}/${url}`);
};

export const getPageById = async (id: number | string): APIResponse<umbraco.Page[]> => {
    return getAPI<umbraco.Page[]>(`content/${BASE_CONTENT_URL}/content`, { id });
};

export const getPageByIdGeneric = async <T>(id: number | string): APIResponse<T[]> => {
    return getAPI<T[]>(`content/${BASE_CONTENT_URL}/content`, { id });
};

export const getForm = async (formId: string): APIResponse<umbraco.UmbracoForm> => {
    return fetchContentAPI<umbraco.UmbracoForm>(`${BASE_CONTENT_URL}/forms/${formId}`);
};

export const postUmbracoForm = async (formPost: FormPostRequest): APIResponse<string> => {
    return postAPI(`content/${BASE_CONTENT_URL}/forms`, formPost);
};

export const getPageByDataTypeAlias = async (alias: umbraco.SiteName | string): APIResponse<umbraco.Page[]> => {
    return getAPI<umbraco.Page[]>(`content/${BASE_CONTENT_URL}/content/alias/${alias}`);
};

export const getPageByDataTypeAliasGeneric = async <T>(alias: string): APIResponse<T> => {
    return getAPI<T>(`content/${BASE_CONTENT_URL}/content/alias/${alias}`);
};
