import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
    name: 'default',
    palette: {
        common: {
            black: '#1B1B1B',
            white: '#fff',
            offWhite: '#fafafa',
            darkBlue: '#0A2841',
            lightCyan: '#C2D1D3',
        },
        error: {
            main: '#D64469',
        },
        primary: {
            main: '#00ADEF',
        },
        success: {
            main: '#00D100',
        },
        grey: {
            main: '#F1F1F2',
            medium: '#E2E2E4',
        },
        text: {
            disabled: '#AEAEAF',
            hint: '#688488',
            primary: '#0B0B0B',
            secondary: '#16181A',
            label: '#231F20',
            placeholder: '#AEAEAF',
            light: '#FFFFFF',
        },
        background: {
            default: '#F7F7F8',
        },
        footer: {
            background: '#002134',
            color: '#FCFCFC',
        },
    },
    shadows: {
        small: '0 2px 10px 0 rgba(0, 0, 0, 0.05)',
        large: '0 2px 10px 0 rgba(0, 0, 0, 0.1)',
    },
    shape: {
        rounded: {
            borderRadius: 20,
            borderRadiusSm: 14,
        },
    },
    fontFamily: {
        primary: "'Corporate E'",
        secondary: "'Avenir Next W01'",
    },
    typography: {
        // General
        htmlFontSize: 16,
        fontFamily: "'Avenir Next W01'",
        fontSize: 14,
        letterSpacing: 0,
        fontWeight: {
            light: 300,
            regular: 400,
            medium: 500,
            bold: 700,
        },
        bodyDefault: {
            fontSize: 14,
            fontWeight: 300,
            lineHeight: 1.4,
        },
        small: {
            fontSize: 10,
            fontWeight: 'regular',
            letterSpacing: 0,
            lineHeight: 23,
        },
        footer: {
            fontSize: 17,
            fontWeight: '700',
            sectionHeaderFontWeight: 700,
        },
        // Form elements
        inputLabel: {
            fontSize: 13,
            fontWeight: 600,
            letterSpacing: 0.3,
            lineHeight: 18,
        },
        inputSubLabel: {
            fontSize: 10,
            fontWeight: 600,
            letterSpacing: 0.5,
            lineHeight: 10,
        },
        inputValue: {
            fontSize: 13,
            fontWeight: 'medium',
            letterSpacing: 0.3,
            lineHeight: 18,
        },
        inputPlaceholder: {
            fontSize: 15,
            fontWeight: 'regular',
            letterSpacing: 0,
            lineHeight: 20,
        },
        inputCheckbox: {
            borderRadius: 0,
            fontSize: 14,
            fontWeight: 'inherit',
            size: 17,
        },
        inputRadio: {
            fontSize: 14,
            fontWeight: 'inherit',
            size: 17,
        },
    },
    iconography: {
        default: {
            stroke: '#000',
        },
        light: {
            stroke: '#fff',
        },
        primary: {
            stroke: '#00ADEF',
        },
        defaultCircular: {
            stroke: '#000',
            background: '#fff',
        },
        success: {
            stroke: '#00D100',
        },
        danger: {
            stroke: '#D64469',
        },
        lightGray: {
            stroke: '#688488',
        },
        lightCircular: {
            stroke: '#fff',
            background: '#000',
        },
        primaryCircular: {
            stroke: '#00ADEF',
            background: '#fff',
        },
        successCircular: {
            stroke: '#00D100',
            background: '#fff',
        },
        dangerCircular: {
            stroke: '#D64469',
            background: '#fff',
        },
    },
    buttonDefault: {
        fontSize: 13,
        fontWeight: 600,
        borderRadius: 20,
        minHeight: 45,
    },
    forms: {
        borderColorDefault: '#00ADEF',
        borderColorHover: '#00ADEF',
        disabledBorderColor: '#0B0B0B1A',
        disabledBackgroundColor: '#0B0B0B1A',
        inputColorDefault: '#000000',
        groupCaptionFontSize: 14,
        groupCaptionSpacing: 7,
        fontSize: 13,
        fontWeight: 500,
        letterSpacing: 0,
    },
};
