import { BlockAlias } from '.';
import { Variant } from '../../../ui-types';
import { ReepayAgreement } from '../reepay';
import { UmbracoForm } from './forms.types';

export type ElementPosition = 'Left' | 'Center' | 'Right';

export type CtaUrl = {
    name: string;
    target?: string;
    url: string;
    type: UrlType;
};

export enum UrlType {
    Internal = 0,
    Media = 1,
    External = 2,
}

export type MediaType = 'image' | 'file';

export type BaseMedia = { id: number; src: string; name: string; extension: string; type: MediaType };

export type Image = BaseMedia & {
    height?: number;
    width?: number;
};

export type Video = BaseMedia;

export type GenericFile = BaseMedia;

export type MathOperation = 'Addition' | 'Subtraction' | 'Multiplication' | 'Division';

/**Mixed Cta */
type BaseMixedCta<T extends 'cTAWithLink' | 'cTAWithAction' | 'megaMenuLink'> = BlockAlias<T> & {
    color?: Variant;
    text: string;
};

export type AppAction =
    | 'Display savings calculation'
    | 'Become a member'
    | 'Mit Hessel Star Plus Dialog'
    | 'Select benefit agreement'
    | 'Open form'
    | 'Scroll to anchor'
    | 'Open service agreement calculator right panel';

export type CtaWithLink = BaseMixedCta<'cTAWithLink'> & {
    linkUrl: CtaUrl;
};

export type CtaWithAction = BaseMixedCta<'cTAWithAction'> & {
    action: AppAction;
    benefitAgreementToSelect: ReepayAgreement;
    formToOpen: string | UmbracoForm;
    scrollToAnchorId: string;
};

export type MegaMenuLink = BaseMixedCta<'megaMenuLink'> & {
    title: string;
};

export type MixedCta = CtaWithLink | CtaWithAction | MegaMenuLink;

export type PageSection = 'Header' | 'Body' | 'Footer';

export type Breadcrumb = {
    name: string;
    url: string;
};

export type ScrollDirection = 'Up' | 'Down';

export type LabelAndIcon = {
    label: string;
    icon?: Image;
    isEmphasized?: boolean;
    tooltip?: string;
    availability?: string;
};

export type UspTemplate = {
    header: string;
    description: string;
    topSideIcon: Image;
    bottomSideIcon: Image;
};

export type FormSubmissionStatus = 'Initial' | 'Submitted';
