export function sanitizeMarkup(markup: string | undefined): {
    dangerouslySetInnerHTML: {
        __html: string;
    };
} {
    return {
        dangerouslySetInnerHTML: {
            __html: (markup || '').replace(/<script/g, '&lt;script'),
        },
    };
}
