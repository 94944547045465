export type GetVehicleInfoParams = {
    licensePlate: string;
};

export type GetVehicleInfoResponse = VehicleInformation;

export type VehicleInformation = {
    knownMake?: boolean;
    knownCar?: boolean;
    makeId?: string;
    class?: string;
    model?: string;
    variant?: string;
    fuelTypeId?: FuelType;
    preferredStoreId?: string;
    serviceAgreement?: string;
    hasStoredTires?: boolean;
};

export enum FuelType {
    Benzin = 1,
    Diesel = 2,
    EL = 4,
}
