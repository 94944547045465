import { css } from 'styled-components';

export const gap = (size: number): ReturnType<typeof css> => css`
    /* Safari */
    @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
            & > * + * {
                margin-top: ${size}rem;
            }
        }
    }
`;
