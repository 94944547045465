import { PlusSubscriptionPage, sharedTypes } from '.';
import { MixedCta } from './shared-types';

export type ReceiptUi = {
    receiptHeader: string;
    text: string;
    pickupTimeText: string;
    supportEmail: string;
    supportTel: string;
    showUpTimeText: string;
    submitButtonLink: {
        target?: string;
        url: string;
    };
    downloadBookingButtonText: string;
    addBookingToCalendarText: string;
    benefitAgreements?: BenefitAgreementPromotion[];
};

export type BenefitAgreementPromotion = {
    header: string;
    benefitAgreement: BenefitAgreement;
    image: sharedTypes.Image;
    mobileImage?: sharedTypes.Image;
    receiptUrl: string;
    ctas?: MixedCta[];
    benefitAgreementPage?: PlusSubscriptionPage;
};
type BenefitAgreement = {
    agreementID: string;
    agreementName: string;
    benefits: string[];
    supportedMakes?: { make: string }[];
};

export enum ReceiptPlaceholder {
    Workshop = '{{workshop_name}}',
    Date = '{{selected_date}}',
    Car = '{{selected_car}}',
    ShowUpTime = '{{showup_time}}',
    Email = '{{email}}',
    SupportTel = '{{support_tel}}',
    SupportEmail = '{{support_email}}',
    Name = '{{name}}',
    NewLine = '{{new_line}}',
    AddBooking = '{{add_booking}}',
    AgreementName = '{{agreement_name}}',
}

export type ReepayReceiptContent = {
    header: string;
    receiptText: string;
    formSummaryHeader: string;
    emailLabel: string;
    phoneLabel: string;
    ctas: Array<sharedTypes.MixedCta>;
};
