export const SITE_ID = process.env.NEXT_PUBLIC_SITE_ID;

export enum SITE_NAME {
    CARSAVER = 'carsaver',
    HESSEL = 'hessel',
    HESSELBUS = 'hesselbus',
    HESSELPOWER = 'hesselpower',
}

export const isSiteName = (siteName: string): boolean => {
    return SITE_ID === siteName;
};
