import { createContextStore, StoreModelInitializer, EasyPeasyConfig } from 'easy-peasy';
import { ErrorType } from '../../api';
import { ErrorInjections } from './context-store.types';

const dummyPushError = (error: ErrorType) => console.error(error);

export const createContextStoreWithRuntimeModel = <
    StoreModel extends Record<string, unknown>,
    RuntimeModel extends Record<string, unknown> = StoreModel,
    Injections extends ErrorInjections = ErrorInjections,
    StoreConfig extends EasyPeasyConfig<any, any> = EasyPeasyConfig<undefined, Injections>
>(
    model: StoreModelInitializer<StoreModel, RuntimeModel>,
    config?: StoreConfig
) => createContextStore<StoreModel, Injections, RuntimeModel>(model, { ...config, injections: { pushError: dummyPushError, ...config?.injections } });
