import { action } from 'easy-peasy';
import { createContextStoreWithRuntimeModel } from '../helpers/context-store.helpers';
import { UserConsentActions, UserConsentInjections, UserConsentState, UserConsentStoreModel } from './user-consent.types';

const userConsentDefaultState = (): UserConsentState => ({
    cookieBotAccepted: false,
});

const userConsentActions = (): UserConsentActions => ({
    setCookieBotAccepted: action((state, payload) => {
        state.cookieBotAccepted = payload;
    }),
});

export const UserConsentStore = createContextStoreWithRuntimeModel<UserConsentStoreModel, Partial<UserConsentState>, UserConsentInjections>(
    () => ({
        ...userConsentDefaultState(),
        ...userConsentActions(),
    }),
    { name: 'userConsentStore' }
);
