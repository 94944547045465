import { action } from 'easy-peasy';
import { createContextStoreWithRuntimeModel } from '../../helpers/context-store.helpers';
import { ProductOwnershipActions, ProductOwnershipInjections, ProductOwnershipState, ProductOwnershipStoreModel } from './product-ownership.types';

const productOwnershipDefaultState = (): ProductOwnershipState => ({ ownershipMode: undefined, ownershipTab: undefined });

const productOwnershipActions = (): ProductOwnershipActions => ({
    setOwnershipMode: action((state, payload) => {
        state.ownershipMode = payload;
    }),
    setOwnershipTab: action((state, payload) => {
        state.ownershipTab = payload;
    }),
});

export const ProductOwnershipStore = createContextStoreWithRuntimeModel<
    ProductOwnershipStoreModel,
    Partial<ProductOwnershipState>,
    ProductOwnershipInjections
>(
    () => ({
        ...productOwnershipDefaultState(),
        ...productOwnershipActions(),
    }),
    { name: 'productOwnershipStore' }
);
