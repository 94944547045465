export function getInstrumentationKey(): string {
    switch (process.env.NEXT_PUBLIC_BUILD_NAME) {
        case 'development':
            return '54e652d3-fa50-4567-8e5e-0efb109ed0ca';
        case 'staging':
            return 'b71ccc0b-b260-4707-bd2c-10c4f0253648';
        case 'production':
            return '365fbc00-8a85-4fa0-9200-46f03346ec84';
        case 'production':
            return '00000000-0000-0000-0000-000000000000';
        default:
            throw new Error('[application-insights.helpers.ts]-[getInstrumentationKey]: Invalid build name');
    }
}
