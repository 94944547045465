/**
 *
 * @param isVisible
 * @param lockBodyScroll This parameter is used to handle scroll issues on Safari:iOS15, since
 * overflow=hidden doesn't work properly on the aforementioned browser.
 */
export function handleOverflow(isVisible: boolean, lockBodyScroll?: boolean): void {
    if (process.browser) {
        if (document.activeElement instanceof HTMLElement && isVisible) {
            document.activeElement.blur();
        }

        const html = document.querySelector('html');
        if (html) {
            html.style.overflow = isVisible ? 'hidden' : 'revert';
            html.style.touchAction = isVisible ? 'none' : 'revert';

            if (lockBodyScroll) {
                html.style.height = isVisible ? '100%' : 'revert';
            } else {
                html.style.height = 'revert';
            }
        }

        const body = document.querySelector('body');
        if (body) {
            body.style.overflow = isVisible ? 'hidden' : 'revert';
            body.style.touchAction = isVisible ? 'none' : 'revert';

            if (lockBodyScroll) {
                body.style.height = isVisible ? '100%' : 'revert';
            } else {
                body.style.height = 'revert';
            }
        }
    }
}
