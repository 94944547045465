export function formatPrice(price: number, numberOfDecimals = 0): string {
    return (Math.round(price * 100) / 100)
        .toFixed(numberOfDecimals)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function calculateDiscount(currentPrice: number, beforePrice: number): number {
    return Math.round(100 - (currentPrice * 100) / beforePrice);
}

export function getFormattedValue(value?: number): string {
    if (value !== undefined) {
        return new Intl.NumberFormat('da-DK', {
            localeMatcher: 'lookup',
            style: 'decimal',
        }).format(value);
    }

    return '';
}

export function getShopPrice(price: number): string {
    const formatted = formatPrice(price, 2);
    return `${formatted} kr.`;
}
