import ical from 'ical-generator';
import { ICal } from '../../lib/booking';

export function getICalUrl(settings: ICal): string {
    if (!process.browser) {
        return '';
    }

    const { startTime, endTime, carModel, serviceName, workshopAddress } = settings;

    const cal = ical({
        domain: 'https://www.hessel.dk',
        prodId: {
            company: 'Ejner Hessel',
            product: 'booking-system',
        },
        name: 'EJH Booking System',
        timezone: 'Europe/Copenhagen',
        events: [
            {
                start: startTime.toISOString(),
                end: endTime.toISOString(),
                summary: `Ejner Hessel: Værkstedsbesøg for ${carModel}`,
                description: `${serviceName}`,
                location: `${workshopAddress}`,
                alarms: [
                    {
                        trigger: 60 * 60 * 24,
                        type: 'display',
                    },
                ],
            },
        ],
    });

    return cal.toURL();
}
