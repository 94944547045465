import { hesselViewModels } from '../../lib/view-models';

export function getVehicleAvailabilityText(availability: hesselViewModels.VehicleAvailability | string): string {
    switch (availability) {
        case 'InStock':
            return 'Lagerbil';
        case 'Order':
            return 'Bestillingsbil';

        default:
            return 'Unknown';
    }
}

export function firstLetterToUpperCase(text: string): string {
    const [firstLetter, ...rest] = text;
    return firstLetter.toUpperCase() + rest.join('');
}

export function isNullOrEmpty(text?: string): boolean {
    return !text || text.length === 0;
}

export function isNumber(text?: string): boolean {
    return !isNaN(Number(text));
}

export function extensiveIsNullOrEmpty(test: string | null | undefined): boolean {
    if (!test || test === undefined || test === null || test === '' || test.length === 0) return true;
    return false;
}
