import { addHours, addMinutes, format, getDay, isSameDay, set } from 'date-fns';
import { da } from 'date-fns/locale';
import { isEqual } from 'date-fns';
import { DealershipInformationPage } from '../../lib/api/models/umbraco';
import { DepartmentType, OpeningHour, SpecialDay } from '../../lib/api/models/umbraco/organization.types';

export enum DateStyle {
    dk_text,
    dk_full_text,
    dk_full_text_with_year,
    dk_full_text_hh_mm,
    HH_mm,
    yyyy_mm_dd,
    yyyy_mm_dd_with_dot,
    booking_format,
    simple_danish,
    simple_danish_with_dot,
    yyyy_mm_dd_hh_ss,
    year_only,
    day_name,
}

export function formatDate(date: Date | number, f: DateStyle = DateStyle.yyyy_mm_dd): string {
    let dateFormat = '';

    switch (f) {
        case DateStyle.dk_text:
            dateFormat = `E 'd.' d MMM`;
            break;
        case DateStyle.dk_full_text:
            dateFormat = `EEEE 'd.' d MMMM`;
            break;
        case DateStyle.dk_full_text_with_year:
            dateFormat = `EEEE 'd.' d MMMM yyyy`;
            break;
        case DateStyle.dk_full_text_hh_mm:
            dateFormat = `EEEE 'd.' d MMMM HH:mm`;
            break;
        case DateStyle.HH_mm:
            dateFormat = 'HH:mm';
            break;
        case DateStyle.yyyy_mm_dd:
            dateFormat = 'yyyy-MM-dd';
            break;
        case DateStyle.yyyy_mm_dd_with_dot:
            dateFormat = 'yyyy.MM.dd';
            break;
        case DateStyle.simple_danish_with_dot:
            dateFormat = 'dd.MM.yyyy';
            break;
        case DateStyle.simple_danish:
            dateFormat = 'dd-MM-yyyy';
            break;
        case DateStyle.booking_format:
            return new Date(date).toISOString();
        case DateStyle.yyyy_mm_dd_hh_ss:
            dateFormat = `yyyy-MM-dd'T'HH:mm:ss`;
            break;
        case DateStyle.year_only:
            dateFormat = `yyyy`;
            break;
        case DateStyle.day_name:
            dateFormat = `EEEE`;
            break;
        default:
            dateFormat = 'yyyy-MM-dd';
    }

    try {
        return format(date, dateFormat, {
            locale: da,
        });
    } catch (error) {
        console.error(`eh.spa > formatDate: ${error}`);
        return '';
    }
}

export function increaseHours(date: Date | number, amount: number): Date {
    return addHours(date, amount);
}

export function increaseHoursAndMinutes(date: Date | number, hours: number, minutes: number): Date {
    return addHours(addMinutes(date, minutes), hours);
}

export function isDateDisabled(day: Date, availableDays: Date[]): boolean {
    if (!availableDays || availableDays.length === 0) {
        return true;
    }

    return !availableDays.some((date) => isEqual(date, day));
}

export function getEndTime(date: Date): Date {
    return set(
        date,
        getDay(date) === 5 ? { hours: 15, minutes: 0, seconds: 0, milliseconds: 0 } : { hours: 15, minutes: 30, seconds: 0, milliseconds: 0 }
    );
}

export function getRemainingDaysInMonth(date: Date): number {
    const time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    time.setDate(0);
    const daysRemainingInMonth = time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
    return daysRemainingInMonth;
}

export function generateDates(fromDate: Date, count: number): Date[] {
    const results = [];
    const year = fromDate.getFullYear();
    const month = fromDate.getMonth();

    for (let i = 0; i < count; i++) {
        results.push(new Date(year, month, fromDate.getDate() + i));
    }

    return results;
}

export function dateExists(date: Date, dates: Date[]): boolean {
    return dates.some((d) => isSameDay(date, d));
}

export function generateHours(from: Date, to: Date, interval: number): Date[] {
    const hours = [];
    let time = from;

    while (time <= to) {
        hours.push(time);
        time = addMinutes(time, interval);
    }

    return hours;
}

export function getDaysToFirstAvailableDate(startDate: Date, waitingDays: number, closedDays: number[], specialDays?: SpecialDay[]): number {
    let hits = hitsOnClosedDays(startDate, waitingDays, closedDays);
    const extraClosedDays = specialDays?.filter((x) => x.closed === true);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + waitingDays + hits);
    endDate.setHours(0);
    endDate.setMinutes(0);
    endDate.setSeconds(0);
    endDate.setMilliseconds(0);

    // If the end date is a closed day, increment hits and check again
    while (closedDays.includes(endDate.getDay()) || extraClosedDays?.some((s) => new Date(s.date).getTime() === endDate.getTime())) {
        hits++;
        endDate.setDate(endDate.getDate() + 1);
    }

    return hits;
}

function hitsOnClosedDays(startDate: Date, waitingDays: number, closedDays: number[]): number {
    let hits = 0;

    // List of days generated based on waitingDays
    const dayList: Date[] = [];

    // Generate the list of days starting from startDate + 1
    for (let i = 1; i <= waitingDays; i++) {
        const newDate = new Date(startDate);
        newDate.setDate(startDate.getDate() + i);
        dayList.push(newDate);
    }

    // Iterate over the generated list of days
    for (const day of dayList) {
        // Get the day of the week for the current date
        const dayOfWeek = day.getDay();

        // Check if the store is closed on this day
        if (closedDays.includes(dayOfWeek)) {
            hits++;
        }
    }

    return hits;
}

type DepartmentToCheckForDays = DepartmentType | 'Testdrive';

export function getClosedDaysList(dealership: DealershipInformationPage, departmentToCheck: DepartmentToCheckForDays = 'Testdrive'): Array<number> {
    if (departmentToCheck !== 'Testdrive') {
        const department = dealership.departments.find((x) => x.departmentType === departmentToCheck);
        if (department) return getClosedDaysAsNumberArray(department.openingHours);
    }

    if (dealership && dealership.testDriveOpeningHours && dealership.testDriveOpeningHours.length > 0) {
        const testDriveOpeningHours = dealership.testDriveOpeningHours[0].openingHours.filter((x) => x.closed);
        if (testDriveOpeningHours) return getClosedDaysAsNumberArray(testDriveOpeningHours);
    }

    return [0, 1, 2, 3, 4, 5, 6];
}

const getClosedDaysAsNumberArray = (openingHours: OpeningHour[]) => {
    const closedDaysList = [];
    const closedDays = openingHours.filter((x) => x.closed);
    if (closedDays && closedDays.length > 0) {
        for (const day of closedDays) {
            switch (day.dayOfWeek) {
                case 'Sunday':
                    closedDaysList.push(0);
                    break;
                case 'Monday':
                    closedDaysList.push(1);
                    break;
                case 'Tuesday':
                    closedDaysList.push(2);
                    break;
                case 'Wednesday':
                    closedDaysList.push(3);
                    break;
                case 'Thursday':
                    closedDaysList.push(4);
                    break;
                case 'Friday':
                    closedDaysList.push(5);
                    break;
                case 'Saturday':
                    closedDaysList.push(6);
                    break;
                default:
                    break;
            }
        }
    }
    return closedDaysList;
};
