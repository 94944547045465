import { umbraco } from '../../lib/api';
import { MEDIA_URL } from '../environment-constants';

const SITE_ID = process.env.NEXT_PUBLIC_SITE_ID;

export const replaceLineBreaks = (text?: string): string => {
    if (!text) {
        return '';
    }

    return text.split(umbraco.ReceiptPlaceholder.NewLine).join('<br />');
};

export const cmsUrlWithoutSiteId = (rawUrl: string): string => {
    let returnUrl = rawUrl;
    const topPageNode = '/forside';
    if (SITE_ID && rawUrl?.startsWith(`/${SITE_ID.toString()}`)) {
        returnUrl = rawUrl.substring(SITE_ID.length + 1);
    }
    if (!returnUrl || returnUrl === '') {
        returnUrl = '/';
    }
    if (returnUrl.includes(topPageNode)) {
        returnUrl = returnUrl.replace(topPageNode, '');
    }
    if (returnUrl.startsWith('/media')) {
        returnUrl = MEDIA_URL + returnUrl;
    }
    if (returnUrl !== '/' && returnUrl.endsWith('/')) {
        returnUrl = returnUrl.slice(0, -1);
    }
    return returnUrl;
};

export const getCmsImageUrl = (baseUrl: string, queryParams?: string[]): string => {
    let imageUrl = baseUrl;
    if (!imageUrl?.startsWith(MEDIA_URL ?? '')) imageUrl = MEDIA_URL + imageUrl;
    if (queryParams) {
        let separator = imageUrl.indexOf('?') > -1 ? '&' : '?';
        for (const param of queryParams) {
            imageUrl = imageUrl + separator + param;
            separator = '&';
        }
    }
    return imageUrl;
};
