import React from 'react';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import { carsaverTheme, defaultTheme, GlobalStyles } from '../themes';
import { FeatureToggleProvider } from '../feature-toggle';
import '../main.css';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.min.css';
import '../styles/slider-styles.css';

// react-toastify styles
import 'react-toastify/dist/ReactToastify.css';

import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { getInstrumentationKey } from '../utils/application-insights.helpers';
import { UserConsentStore } from '../lib/state/user-consent/user-consent.store';
import { ProductOwnershipStore } from '../lib/state/hessel-site/product-ownership/product-ownership.store';
import { SWRConfig } from 'swr';
import { fetcher } from '../lib/api/helpers';
import { ToastContainer } from 'react-toastify';
import DevelopmentStyles from '../themes/development-styles';
import { SITE_NAME, isSiteName } from '../utils/helpers/site-id.helper';

function EjhApp({ Component, pageProps }: AppProps): JSX.Element {
    const SITE_ID = process.env.NEXT_PUBLIC_SITE_ID;
    const ENVIRONMENT = process.env.NEXT_PUBLIC_ENV;
    const reactPlugin = new ReactPlugin();

    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: getInstrumentationKey(),
            extensions: [reactPlugin],
            extensionConfig: {},
        },
    });
    appInsights.loadAppInsights();

    const cookieBotId = (): string => {
        // TODO: Add CarSaver CookieBodId
        if (isSiteName(SITE_NAME.HESSELBUS)) return 'a67e3be4-a54e-46bc-a12b-00a0ce8eb08e';
        if (isSiteName(SITE_NAME.HESSELPOWER)) return '5be26a20-5289-4833-88ba-c3070cdc7976';
        return '9da433dd-ea39-4e16-afef-eaaa6b15bce9';
    };

    return (
        <>
            <Head>
                <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid={cookieBotId()} async type="text/javascript"></script>
            </Head>

            <UserConsentStore.Provider>
                <FeatureToggleProvider>
                    <ThemeProvider theme={getCssTheme(SITE_ID?.toString())}>
                        <GlobalStyles />
                        {ENVIRONMENT === 'development' && <DevelopmentStyles></DevelopmentStyles>}
                        <AppInsightsErrorBoundary onError={() => null} appInsights={reactPlugin}>
                            <AppInsightsContext.Provider value={reactPlugin}>
                                <SWRConfig
                                    value={{
                                        dedupingInterval: 30000,
                                        refreshInterval: 30000,
                                        fetcher,
                                    }}
                                >
                                    <ProductOwnershipStore.Provider>
                                        <Component {...pageProps} />
                                    </ProductOwnershipStore.Provider>
                                </SWRConfig>
                                <ToastContainer />
                            </AppInsightsContext.Provider>
                        </AppInsightsErrorBoundary>
                    </ThemeProvider>
                </FeatureToggleProvider>
            </UserConsentStore.Provider>
        </>
    );
}

function getCssTheme(siteId: string | undefined) {
    const theme = siteId === 'carsaver' ? carsaverTheme : defaultTheme;
    return theme;
}

export function reportWebVitals(metric: NextWebVitalsMetric): void {
    console.log('Webvitals', metric.name, Math.round(metric.value * 100) / 100, 'ms');
}

export default EjhApp;
