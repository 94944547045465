export const FindEmployeesSpot = 'hire-employees-spot';
export const SalesAndServiceSpot = 'dealership-sales-and-service-spot';
export const DemoCarsRibbonSpot = 'dealership-demo-cars-ribbon-spot';
export const MAP_ZOOM_LEVEL = 17;
export const MAP_NO_LOCATION_ZOOM_LEVEL = 6;
export const DEALERSHIP_MAP_ZOOM_LEVEL = 16;

// This is the default position of the map when no location is selected
// Its purpose is to show the whole Denmark
export const DEFAULT_MAP_POSITION = {
    lat: 56.0881,
    lng: 8.2576,
};

export const DEFAULT_MAP_POSITION_Mobile = {
    lat: 55.4038,
    lng: 10.4024,
};
