import { getFeatureToggles } from '../lib/api';
import { createContext, FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { FeatureToggle } from './feature-toggle.types';

export const FeatureToggleContext = createContext<FeatureToggle[]>([]);

type Props = {
    children: ReactNode;
};

const FeatureToggleProvider: FC<Props> = ({ children }) => {
    const getToggles = useCallback(async () => {
        const [result, error] = await getFeatureToggles();

        if (result && !error) {
            const newToggles: FeatureToggle[] = result.map((value) => {
                const [group, toggle] = value.split('.');
                return { group, toggle };
            });

            return newToggles;
        }
    }, []);

    const [featureToggles, setFeatureToggles] = useState<FeatureToggle[]>([]);

    useEffect(() => {
        const get = async () => {
            const toggles = await getToggles();
            if (toggles) {
                setFeatureToggles(toggles);
            }
        };
        get();
    }, [getToggles, setFeatureToggles]);

    return <FeatureToggleContext.Provider value={featureToggles}>{children}</FeatureToggleContext.Provider>;
};

export default FeatureToggleProvider;
