import { DefaultTheme } from 'styled-components';

const themeColors = {
    primary: '#F7941D',
    secondary: '#FFFFFF',
    tertiary: '#000000',
    textColorDark: '#0B0B0B',
    textColorLight: '#FFFFFF',
};

const themeFonts = {
    primary: "'Paralucent', sans-serif",
    secondary: "'Paralucent', sans-serif",
};

export const carsaverTheme: DefaultTheme = {
    name: 'car-saver',
    palette: {
        common: {
            black: '#0B0B0B',
            white: '#fff',
            offWhite: '#fafafa',
            darkBlue: '#0A2841',
            lightCyan: '#C2D1D3',
        },
        error: {
            main: '#D64469',
        },
        primary: {
            main: themeColors.primary,
        },
        success: {
            main: '#00D100',
        },
        grey: {
            main: '#F1F1F2',
            medium: '#E2E2E4',
        },
        text: {
            disabled: '#AEAEAF',
            hint: '#688488',
            primary: '#0B0B0B',
            secondary: '#16181A',
            label: '#231F20',
            placeholder: '#AEAEAF',
            light: themeColors.secondary,
        },
        background: {
            default: themeColors.secondary,
        },
        footer: {
            background: themeColors.primary,
            color: '#1B1B1B',
        },
    },
    shadows: {
        small: '2px 2px 6px 0 rgba(0, 0, 0, 0.05)',
        large: '2px 6px 10px 0 rgba(0, 0, 0, 0.1)',
    },
    shape: {
        rounded: {
            borderRadius: 20,
            borderRadiusSm: 14,
        },
    },
    fontFamily: {
        primary: themeFonts.primary,
        secondary: themeFonts.primary,
    },
    typography: {
        // General
        htmlFontSize: 16,
        fontFamily: themeFonts.primary,
        fontSize: 16,
        letterSpacing: 0.5, // PX
        fontWeight: {
            light: 200,
            regular: 400,
            medium: 500,
            bold: 900,
        },
        bodyDefault: {
            fontSize: 16,
            fontWeight: 200,
            lineHeight: 1.5,
        },
        small: {
            fontSize: 14,
            fontWeight: 'regular',
            letterSpacing: 0,
            lineHeight: 23,
        },
        footer: {
            fontSize: 16,
            fontWeight: 500,
            sectionHeaderFontWeight: 900,
        },
        // Form elements
        inputLabel: {
            fontSize: 14,
            fontWeight: 900,
            letterSpacing: 0.5, // PX
            lineHeight: 18,
        },
        inputSubLabel: {
            fontSize: 10,
            fontWeight: 900,
            letterSpacing: 0.5, // PX
            lineHeight: 10,
        },
        inputValue: {
            fontSize: 14,
            fontWeight: 400,
            letterSpacing: 0.5, // PX
            lineHeight: 18,
        },
        inputPlaceholder: {
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: 1,
            lineHeight: 20,
        },
        inputCheckbox: {
            borderRadius: 4,
            fontSize: 16,
            fontWeight: 'inherit',
            size: 24,
        },
        inputRadio: {
            fontSize: 16,
            fontWeight: 'inherit',
            size: 24,
        },
    },
    iconography: {
        default: {
            stroke: '#000',
        },
        light: {
            stroke: '#fff',
        },
        primary: {
            stroke: '#00ADEF',
        },
        defaultCircular: {
            stroke: '#000',
            background: '#fff',
        },
        success: {
            stroke: '#00D100',
        },
        danger: {
            stroke: '#D64469',
        },
        lightGray: {
            stroke: '#688488',
        },
        lightCircular: {
            stroke: '#fff',
            background: '#000',
        },
        primaryCircular: {
            stroke: '#00ADEF',
            background: '#fff',
        },
        successCircular: {
            stroke: '#00D100',
            background: '#fff',
        },
        dangerCircular: {
            stroke: '#D64469',
            background: '#fff',
        },
    },
    buttonDefault: {
        fontSize: 16,
        fontWeight: 900,
        borderRadius: 8,
        minHeight: 51,
    },
    forms: {
        borderColorDefault: '#0B0B0B1A',
        borderColorHover: themeColors.primary,
        disabledBorderColor: '#0B0B0B1A',
        disabledBackgroundColor: '#0B0B0B1A',
        inputColorDefault: themeColors.textColorDark,
        groupCaptionFontSize: 16,
        groupCaptionSpacing: 8,
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: 0.5, // PX
    },
};
