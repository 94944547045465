import { addDays, differenceInCalendarDays, parseISO, set, subMinutes } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import { umbraco } from '../../../lib/api';
import { AppointmentAvailability, TimeSlot } from '../../../lib/api/models/hessel-api';
import { Holiday } from '../../../lib/api/models/umbraco';
import { dateExists, generateDates } from '../date.helper';

export function generateServiceTooLongAppointmentAvailability(
    leadTimeInDays: number,
    dateFrom: Date,
    dateTo: Date,
    serviceTooLongHolidays: Holiday[]
): AppointmentAvailability<string>[] {
    const appointmentAvailabilities: AppointmentAvailability<string>[] = [];

    const minimumLeadTimeDate = addDays(Date.now(), leadTimeInDays);
    if (dateFrom < minimumLeadTimeDate) {
        dateFrom = minimumLeadTimeDate;
    }

    const currentYear = new Date().getFullYear();
    const unavailableDates: Date[] = serviceTooLongHolidays
        .map(({ holiday }) => parseISO(holiday))
        .map((holiday) => new Date(currentYear, holiday.getMonth(), holiday.getDate()));

    for (const date of generateDates(dateFrom, differenceInCalendarDays(addDays(dateTo, 1), dateFrom))) {
        const weekDay = date.getDay();
        const available = ![0, 6].includes(weekDay) && !dateExists(date, unavailableDates);
        const times = generateLongServiceAvailableTimes(date);

        appointmentAvailabilities.push({
            date: date.toISOString(),
            timeSlots: times.map((t) => generateTimeSlot(t, available, 0, 0)),
        });
    }
    return appointmentAvailabilities;
}

function generateTimeSlot(date: Date, available: boolean, estimatedWorkHours: number, estimatedWorkMinutes: number): TimeSlot<string> {
    return {
        startDateTime: date.toISOString(),
        endDateTime: date.toISOString(),
        available: available,
        estimatedWorkHours: estimatedWorkHours,
        estimatedWorkMinutes: estimatedWorkMinutes,
    };
}

function generateLongServiceAvailableTimes(date: Date): Date[] {
    const utcDate1 = set(date, {});
    const utcDate2 = set(date, {});

    utcDate1.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    utcDate2.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    utcDate1.setUTCHours(7, 30);
    utcDate2.setUTCHours(8, 0);

    const danishLocalTimeOffset = getTimezoneOffset('Europe/Copenhagen', utcDate1) / 1000 / 60;

    const danishDate1 = subMinutes(utcDate1, danishLocalTimeOffset);
    const danishDate2 = subMinutes(utcDate2, danishLocalTimeOffset);

    return [danishDate1, danishDate2];
}

export function getLeadTimeForStore(content: umbraco.BookingStepWorkshop, storeId: string): number {
    const leadTimeForStore = content.serviceTooLongLeadTimes?.find((s) => s.sabId === storeId)?.leadTime;
    const defaultLeadTime = content.serviceTooLongLeadTime;
    return leadTimeForStore ?? defaultLeadTime;
}
