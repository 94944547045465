import { css } from 'styled-components';
import type { FontType } from '../font.types';

export const typography = (font: FontType): ReturnType<typeof css> => css`
    ${({ theme: { typography } }) => {
        const { fontSize, fontWeight, letterSpacing, lineHeight } = typography[font];

        return {
            fontSize: `${fontSize}px`,
            letterSpacing: `${letterSpacing}px`,
            lineHeight: `${lineHeight}px`,
            fontWeight: typeof fontWeight === 'number' ? fontWeight : typography.fontWeight[fontWeight],
        };
    }}
`;
