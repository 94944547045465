import { ParsedUrlQueryInput, stringify } from 'querystring';
import { IS_PRODUCTION } from '../../utils/environment-constants';
import { isNullOrEmpty } from '../../utils/helpers';
import { APIResponse, ErrorResponse, ServiceAgreementCalculatorErrorResponse } from './types';

const getAPIUrl = (): string =>
    process.env.NEXT_PUBLIC_API_URL && !isNullOrEmpty(process.env.NEXT_PUBLIC_API_URL) ? process.env.NEXT_PUBLIC_API_URL : '';

const defaultHeaders = () => {
    const header = new Headers();

    header.append('Content-Type', 'application/json');

    return header;
};

export const parseQuery = <T extends ParsedUrlQueryInput>(query?: T): URLSearchParams =>
    query ? new URLSearchParams(stringify(query)) : new URLSearchParams();

export const getAPI = async <Response = unknown, T extends ParsedUrlQueryInput = ParsedUrlQueryInput>(
    url: string,
    query?: T,
    signal?: AbortSignal
): APIResponse<Response> => {
    const queryUrl = new URL(url, getAPIUrl());
    queryUrl.search = parseQuery(query).toString();

    if (!IS_PRODUCTION) {
        console.log('Querying', queryUrl);
    }

    const result = await fetch(queryUrl.toString(), {
        method: 'GET',
        headers: defaultHeaders(),
        keepalive: true,
        signal: signal,
    });

    try {
        const json = await result.json();

        if (!result.ok) {
            return [
                undefined,
                {
                    errorType:
                        (json as ErrorResponse).bookingApiErrorType ??
                        (json as ServiceAgreementCalculatorErrorResponse).serviceAgreementCalculatorApiErrorType,
                    statusCode: result.status,
                },
            ];
        }

        return [json, undefined];
    } catch (error) {
        console.error(error);
        return [undefined, undefined];
    }
};

type ParamValue = string | number | boolean;

export const generateQueryString = (params: { [key: string]: ParamValue | undefined }): string =>
    Object.entries(params)
        .filter(([, value]) => Boolean(value))
        .map(([key, value]) => `${key}=${encodeURIComponent(value as ParamValue)}`)
        .join('&');

export const fetcher = async <T>(input: RequestInfo, init: RequestInit = {}): Promise<T> => {
    try {
        const url = `${getAPIUrl()}${input}`;

        if (!IS_PRODUCTION) {
            console.log('Querying', url, init);
        }

        const response = await fetch(url, {
            headers: defaultHeaders(),
            keepalive: true,
            ...init,
        });

        const result: T | ErrorResponse | undefined = await response.json();
        if (response.ok) {
            return result as T;
        }

        throw new Error(
            `SWR Fetch error ${response.status}: ${input instanceof Request ? input.url : input}. Error: ${
                (result as ErrorResponse).bookingApiErrorType
            }`
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchContentAPI = async <Response = unknown>(query: string): APIResponse<Response> => {
    return getAPI<Response>(`content/${query}`);
};

export const postAPI = async <Response = unknown, T = unknown>(url: string, body?: T): APIResponse<Response> => {
    const postUrl = new URL(url, getAPIUrl());

    if (!IS_PRODUCTION) {
        console.log('Posting', postUrl);
    }

    const result = await fetch(postUrl.toString(), {
        method: 'POST',
        headers: defaultHeaders(),
        body: JSON.stringify(body),
    });

    const json = await result.json();

    if (!result.ok) {
        const bookingAPIError = json['bookingApiErrorType'];
        const paymentAPIError = json['paymentApiErrorType'];
        if (bookingAPIError) {
            return [undefined, { errorType: (json as ErrorResponse).bookingApiErrorType, statusCode: result.status }];
        } else if (paymentAPIError) {
            return [undefined, { errorType: paymentAPIError, statusCode: result.status }];
        }
        return [undefined, { errorType: 'UNKNOWN', statusCode: result.status }];
    }

    return [json, undefined];
};

export const putAPI = async <Response = unknown, T = unknown>(url: string, body: T): APIResponse<Response> => {
    const putUrl = new URL(url, getAPIUrl());

    if (!IS_PRODUCTION) {
        console.log('Putting', putUrl);
    }

    const result = await fetch(putUrl.toString(), {
        method: 'PUT',
        headers: defaultHeaders(),
        body: JSON.stringify(body),
    });

    const json = await result.json();

    if (!result.ok) {
        const bookingAPIError = json['bookingApiErrorType'];
        const paymentAPIError = json['paymentApiErrorType'];
        if (bookingAPIError) {
            return [undefined, { errorType: (json as ErrorResponse).bookingApiErrorType, statusCode: result.status }];
        } else if (paymentAPIError) {
            return [undefined, { errorType: paymentAPIError, statusCode: result.status }];
        }
        return [undefined, { errorType: 'UNKNOWN', statusCode: result.status }];
    }

    return [json, undefined];
};

export const deleteAPI = async <Response = unknown>(url: string): APIResponse<Response> => {
    const deleteUrl = new URL(url, getAPIUrl());

    if (!IS_PRODUCTION) {
        console.log('Deleting', deleteUrl);
    }

    const result = await fetch(deleteUrl.toString(), {
        method: 'DELETE',
        headers: defaultHeaders(),
    });

    const json = await result.json();

    if (!result.ok) {
        const bookingAPIError = json['bookingApiErrorType'];
        const paymentAPIError = json['paymentApiErrorType'];
        if (bookingAPIError) {
            return [undefined, { errorType: (json as ErrorResponse).bookingApiErrorType, statusCode: result.status }];
        } else if (paymentAPIError) {
            return [undefined, { errorType: paymentAPIError, statusCode: result.status }];
        }
        return [undefined, { errorType: 'UNKNOWN', statusCode: result.status }];
    }

    return [json, undefined];
};

export const postMethod = async <Response = unknown, T = unknown>(url: string, body: T): APIResponse<Response> => {
    const postUrl = new URL(url, getAPIUrl());

    if (!IS_PRODUCTION) {
        console.log('Posting', postUrl);
    }

    const result = await fetch(postUrl.toString(), {
        method: 'POST',
        headers: defaultHeaders(),
        body: JSON.stringify(body),
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let responseMessage: any;

    try {
        responseMessage = await result.json();
    } catch (error) {
        responseMessage = result.ok
            ? {
                  statusCode: result.status,
              }
            : {
                  statusCode: result.status,
                  errorType: {
                      bookingApiErrorType: 'UNKNOWN',
                  },
              };
    }

    if (!result.ok) {
        return [undefined, { errorType: (responseMessage as ErrorResponse).bookingApiErrorType, statusCode: result.status }];
    }

    return [responseMessage, undefined];
};

export const getAPIWithBaseUrl = async <Response = unknown, T extends ParsedUrlQueryInput = ParsedUrlQueryInput>(
    url: string,
    baseUrl: string,
    query?: T,
    signal?: AbortSignal
): APIResponse<Response> => {
    try {
        const queryUrl = new URL(url, baseUrl);
        queryUrl.search = parseQuery(query).toString();

        const result = await fetch(queryUrl.toString(), {
            method: 'GET',
            headers: defaultHeaders(),
            keepalive: true,
            signal: signal,
        });

        try {
            const json = await result.json();

            if (!result.ok) {
                return [undefined, { errorType: (json as ErrorResponse).bookingApiErrorType, statusCode: result.status }];
            }

            return [json, undefined];
        } catch (error) {
            console.error(error);
            return [undefined, undefined];
        }
    } catch (error) {
        console.error(error);
        return [undefined, undefined];
    }
};
