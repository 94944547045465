export const breakpoints = {
    /**
     * Small phones
     */
    xs: 320,
    /**
     * Larger phones, tablet portrait
     */
    sm: 415,
    /**
     * Larger phones, tablet portrait
     */
    md: 768,
    /**
     * When the frame should break between mobile / desktop
     */
    frame: 800,
    /**
     * Somewhere in between portrait and landscape
     */
    ml: 1000,
    /**
     * tablet landscape, desktops
     */
    lg: 1024,
    /**
     * tablet landscape, desktops
     */
    xlg: 1300,
    /**
     * Desktops, laptops
     */
    xl: 1600,
    /**
     * Beyond max content width
     */
    max: 1920,
};

export type Breakpoints = typeof breakpoints;

export type BreakpointKeys = keyof Breakpoints;
