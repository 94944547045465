import { FormsSpot } from '../../lib/api/models/umbraco/content-spot';
import { FormInput } from '../../lib/state/booking/form';
import { FormInfoWithId } from '../../lib/state/plus-sites/forms-display-manager';

export const formInputValue = <T>(input: FormInput<T>): T | string => {
    switch (input.type) {
        case 'car':
        case 'email':
        case 'number':
        case 'tel':
        case 'text':
        case 'workshop':
        case 'name':
        case 'company (business)':
        case 'cvr (business)':
            return input.value;

        case 'address':
            return '';

        default:
            return '';
    }
};

export const formInputLabel = <T>(input: FormInput<T>): T | string => {
    switch (input.type) {
        case 'car':
        case 'email':
        case 'number':
        case 'tel':
        case 'text':
        case 'workshop':
        case 'name':
        case 'company (business)':
        case 'cvr (business)':
            return input.label;

        case 'address':
            return '';

        default:
            return '';
    }
};

export function formInfoFromSpot(spot: FormsSpot): FormInfoWithId {
    return {
        form: spot.form,
        formHeader: spot.formHeader,
        formSubtext: spot.formSubtext,
        isVisible: false,
        trackingStrategy: spot.trackingStrategy,
    };
}
