import { firstLetterToUpperCase, formatPrice } from '../../../utils/helpers';

export const getFuelType = (fuelType: string): { key: string; value: string } => {
    if (fuelType === 'El')
        return {
            key: 'Drivmiddel',
            value: fuelType,
        };
    if (fuelType === 'Benzin hybrid' || fuelType === 'Benzin mild hybrid' || fuelType === 'Benzin plug-in hybrid')
        return {
            key: firstLetterToUpperCase(fuelType.toLowerCase().replace('benzin', '').trim()) ?? '-',
            value: 'Benzin + el',
        };
    if (fuelType === 'Diesel hybrid' || fuelType === 'Diesel mild hybrid' || fuelType === 'Diesel plug-in hybrid')
        return {
            key: firstLetterToUpperCase(fuelType.toLocaleLowerCase().replace('diesel', '').trim()) ?? '-',
            value: 'Diesel + el',
        };
    return {
        key: 'Drivmiddel',
        value: fuelType,
    };
};

export const getRange = (range: number | undefined, fuelType: string): { key: string; value: string } => {
    if (fuelType === 'El') return { key: 'RÆKKEVIDDE', value: range ? formatPrice(range) : '-' };
    return { key: 'KM/L', value: range ? formatPrice(range, 1) : '-' };
};

export const getFirstRegFormatted = (firstReg: string): string => {
    if (!firstReg) return '-';
    if (firstReg.split('-').length !== 2) return '-';
    const [month, year] = firstReg.split('-');
    const monthFormatted = month.length === 1 ? `${month}` : month;
    return `${monthFormatted}/${year}`;
};
