import { validateEmail, validateNotEmpty, validateRegistrationNumber, validateTel, validateName } from '../../../../utils/helpers';
import { umbraco } from '../../../api';
import { StepType } from '../steps';
import { FormInput } from './booking-form.types';

export const generateFormId = (step: StepType, type: umbraco.InputType, addressType?: 'address' | 'zip' | 'city'): string =>
    `${step}:${type}${addressType ? `:${addressType}` : ''}`;

const convertInputToFormInput =
    (step: StepType) =>
    (input: umbraco.Input): FormInput<string> => {
        switch (input.inputType) {
            case 'Car Registration Number':
                return {
                    id: generateFormId(step, input.inputType),
                    step,
                    isValid: false,
                    canValidate: false,
                    type: 'car',
                    label: input.inputLabel,
                    placeholder: input.inputPlaceholder,
                    value: '',
                    validationMessage: input.validationMessage,
                    mandatory: input.mandatory,
                };
            case 'Driven Kilometers':
                return {
                    id: generateFormId(step, input.inputType),
                    step,
                    isValid: false,
                    canValidate: false,
                    type: 'number',
                    label: input.inputLabel,
                    placeholder: input.inputPlaceholder,
                    value: '',
                    validationMessage: input.validationMessage,
                    mandatory: input.mandatory,
                };
            case 'Email':
                return {
                    id: generateFormId(step, input.inputType),
                    step,
                    isValid: false,
                    canValidate: false,
                    type: 'email',
                    label: input.inputLabel,
                    placeholder: input.inputPlaceholder,
                    value: '',
                    validationMessage: input.validationMessage,
                    mandatory: input.mandatory,
                };
            case 'Name':
                return {
                    id: generateFormId(step, input.inputType),
                    step,
                    isValid: false,
                    canValidate: false,
                    type: 'name',
                    label: input.inputLabel,
                    placeholder: input.inputPlaceholder,
                    value: '',
                    validationMessage: input.validationMessage,
                    mandatory: input.mandatory,
                };
            case 'Telephone number':
                return {
                    id: generateFormId(step, input.inputType),
                    step,
                    isValid: false,
                    canValidate: false,
                    type: 'tel',
                    label: input.inputLabel,
                    placeholder: input.inputPlaceholder,
                    value: '',
                    validationMessage: input.validationMessage,
                    mandatory: input.mandatory,
                };
            case 'Workshop':
                return {
                    id: generateFormId(step, input.inputType),
                    step,
                    isValid: false,
                    canValidate: false,
                    type: 'workshop',
                    label: input.inputLabel,
                    placeholder: input.inputPlaceholder,
                    value: '',
                    validationMessage: input.validationMessage,
                    infoMessage: input.infoMessage,
                    mandatory: input.mandatory,
                };
            case 'Address':
                return {
                    id: generateFormId(step, input.inputType),
                    isValid: false,
                    canValidate: false,
                    step,
                    type: 'address',
                    mandatory: input.mandatory,
                    inputs: [
                        {
                            multiformInputName: 'address',
                            id: generateFormId(step, input.inputType, 'address'),
                            step,
                            isValid: false,
                            canValidate: false,
                            type: 'text',
                            label: input.addressLabel,
                            placeholder: input.addressPlaceholder,
                            value: '',
                            validationMessage: input.addressValidationMessage,
                        },
                        {
                            multiformInputName: 'zipcode',
                            id: generateFormId(step, input.inputType, 'zip'),
                            step,
                            isValid: false,
                            canValidate: false,
                            type: 'text',
                            label: input.zipCodeLabel,
                            placeholder: input.zipCodePlaceholder,
                            value: '',
                            validationMessage: input.zipCodeValidationMessage,
                        },
                        {
                            multiformInputName: 'city',
                            id: generateFormId(step, input.inputType, 'city'),
                            step,
                            isValid: false,
                            canValidate: false,
                            type: 'text',
                            label: input.cityLabel,
                            placeholder: input.cityPlaceholder,
                            value: '',
                            validationMessage: input.cityValidationMessage,
                        },
                    ],
                };
        }
    };

export const convertBookingStepsToInputs = (step: umbraco.BookingStep): FormInput<string>[] => {
    switch (step.alias) {
        case 'bookingStepYourCar':
            return step.inputList?.map(convertInputToFormInput('YourCar')) ?? [];
        case 'bookingStepServices':
            return step.inputList?.map(convertInputToFormInput('Services')) ?? [];
        case 'bookingStepContactInfo':
            return step.inputList?.map(convertInputToFormInput('ContactInfo')) ?? [];
        default:
            return [];
    }
};

export const validateInput = (input: FormInput<string>): boolean => {
    switch (input.type) {
        case 'text':
        case 'workshop':
            return validateNotEmpty(input.value);

        case 'car':
            return validateNotEmpty(input.value) && validateRegistrationNumber(input.value);

        case 'email':
            return validateNotEmpty(input.value) && validateEmail(input.value);

        case 'tel':
            return validateNotEmpty(input.value) && validateTel(input.value);

        case 'number':
            return validateNotEmpty(input.value) && Number(input.value) > 0;

        case 'address':
            return input.inputs.reduce<boolean>(
                (acc, input) => validateInput(input) && (input.multiformInputName === 'zipcode' ? input.value.length === 4 : true) && acc,
                true
            );

        case 'name':
            return validateNotEmpty(input.value) && validateName(input.value);

        default:
            return true;
    }
};
