import { hesselApi } from '../../../lib/api';

export type BenefitAgreementName = 'HesselPlus' | 'HesselFordPlus' | 'HesselStarPlusBusiness' | 'HesselStarPlusComplete' | null;

export const removeDuplicateProducts = (serviceProducts: hesselApi.ServiceProduct[]): hesselApi.ServiceProduct[] => {
    const uniqueProducts: hesselApi.ServiceProduct[] = [];
    for (const product of serviceProducts) {
        if (!uniqueProducts.some(({ id }) => id === product.id)) {
            uniqueProducts.push(product);
        }
    }
    return uniqueProducts;
};
