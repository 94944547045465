import {
    Plp_Load_More_Context_Key,
    Plp_Load_More_Filter_From_Key,
    Plp_Load_More_No_Filter_From_Key,
    Plp_Load_More_Filter_Product_Card_Key,
} from '../../constants/site-consts';
import { PromotionAvailabilityType } from '../../lib/api/models/umbraco/product-details-promotion.types';
import { hesselViewModels } from '../../lib/view-models';

export function removeLoadMoreKeys(): void {
    removePlpLoadMoreContext();
    removeStoredRequestFrom();
    removeProductCardScrollToId();
}

export function getPlpLoadMoreContext(): string | null {
    return sessionStorage.getItem(Plp_Load_More_Context_Key);
}

export function storePlpLoadMoreContext(url: string, filterConfig: string): void {
    sessionStorage.setItem(Plp_Load_More_Context_Key, `${url}||${filterConfig}`);
}

export function removePlpLoadMoreContext(): void {
    sessionStorage.removeItem(Plp_Load_More_Context_Key);
}

export function getLoadMore(): string | null {
    return sessionStorage.getItem(Plp_Load_More_Filter_From_Key);
}

export function storeLoadMore(value: string): void {
    sessionStorage.setItem(Plp_Load_More_Filter_From_Key, value);
}

export function getNoFilterLoadMore(): string | null {
    return sessionStorage.getItem(Plp_Load_More_No_Filter_From_Key);
}

export function storeNoFilterLoadMore(value: string): void {
    sessionStorage.setItem(Plp_Load_More_No_Filter_From_Key, value);
}

export function removeStoredRequestFrom(): void {
    sessionStorage.removeItem(Plp_Load_More_Filter_From_Key);
}

export function getProductCardScrollToId(): string | null {
    return sessionStorage.getItem(Plp_Load_More_Filter_Product_Card_Key);
}

export function storeProductCardScrollToId(value: string): void {
    sessionStorage.setItem(Plp_Load_More_Filter_Product_Card_Key, value);
}

export function removeProductCardScrollToId(): void {
    sessionStorage.removeItem(Plp_Load_More_Filter_Product_Card_Key);
}

export function isInLoadMoreContext(url: string, filterConfig: string): boolean {
    const prevContext = getPlpLoadMoreContext();
    if (prevContext && prevContext.indexOf('||') > -1) {
        const [prevUrl, prevConfig] = prevContext.split('||');
        if (prevUrl === url && prevConfig === filterConfig) return true;
    }
    return false;
}

/**
 * https://jira.impact.dk/browse/EJH-898
 * @param product
 * @returns
 */
export function showHigherEquipmentLabel(product: hesselViewModels.ProductDetails, selectedTab: hesselViewModels.OwnershipTab): boolean {
    const allEquipment = [
        ...product.extraEquipment[selectedTab],
        ...product.extraEquipmentPackages[selectedTab],
        ...product.standardEquipment[selectedTab],
        ...product.standardEquipmentPackages[selectedTab],
        ...product.standardEquipmentPackages[selectedTab],
    ];

    return allEquipment.some((x) => x.monthlyHirePrice === 0);
}

export function sortProductCardsByProductPrice(products: hesselViewModels.ProductCard[], price: number): hesselViewModels.ProductCard[] {
    const sortedProducts = products.sort((a, b) => {
        if (a.type === 'detailed' && b.type === 'detailed') {
            const aPrice = a.price[0]?.rawPriceValue ?? 0;
            const bPrice = b.price[0]?.rawPriceValue ?? 0;

            const aDiff = Math.abs(aPrice - price);
            const bDiff = Math.abs(bPrice - price);

            return aDiff - bDiff;
        }

        return 0;
    });

    return sortedProducts;
}

export function mapCarPromotionAvailabilityToVehicleAvailability(
    availabilityList: Array<PromotionAvailabilityType>
): Array<hesselViewModels.VehicleAvailability> {
    return availabilityList.map((availability) => {
        switch (availability) {
            case 'Bestilling':
                return 'Order';
            case 'Lager':
                return 'InStock';
            case 'Brugt':
                return 'Used';
            case 'Engros':
                return 'Engros';
            case 'Demo':
                return 'Demo';
            default: {
                const check: never = availability;
                return check;
            }
        }
    });
}
