import { createGlobalStyle } from 'styled-components';
import { device, queryTarget } from '../lib/media-query';

const DevelopmentStyles = createGlobalStyle`
    body::after {
        background: rgba(0,0,0,0.75);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        color: #fff;
        content: 'implicit';
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        font-size: 14px;
        font-weight: 400;
        padding: 0.25em 0.75em;
        position: fixed;
        right: 0;
        bottom: 5px;
        z-index: 2;
        
        @media ${device.mobileS} {
            content: 'mobileS >= ${queryTarget.mobileS}px';
        }
        @media ${device.mobileM} {
            content: 'mobileM >= ${queryTarget.mobileM}px';
        }
        @media ${device.mobileL} {
            content: 'mobileL >= ${queryTarget.mobileL}px';
        }
        @media ${device.mobile_tablet} {
            content: 'mobile_tablet >= ${queryTarget.mobile_tablet}px';
        }
        @media ${device.tablet} {
            content: 'tablet >= ${queryTarget.tablet}px';
        }
        @media ${device.laptop} {
            content: 'laptop >= ${queryTarget.laptop_tablet_landscape}px';
        }
        @media ${device.laptopS} {
            content: 'laptopS >= ${queryTarget.smallDesktop}px';
        }
        @media ${device.desktop} {
            content: 'desktop >= ${queryTarget.desktop}px';
        }
    }
`;

export default DevelopmentStyles;
