import { createGlobalStyle, css } from 'styled-components';
import { SITE_NAME, isSiteName } from '../utils/helpers/site-id.helper';

const GlobalStyles = createGlobalStyle`
    html {
        box-sizing: border-box;
        font-size: ${({ theme: { typography } }) => typography.htmlFontSize};
        scrollbar-width: thin;
    }
  
    *, *::before, *::after {
        box-sizing: border-box;
    }
  
    body {
        font-family: ${({ theme: { typography } }) => typography.fontFamily};
        font-size: ${({ theme: { typography } }) => typography.fontSize}px;
        background-color: ${({ theme: { palette } }) => palette.common.white};
        line-height: ${({ theme: { typography } }) => typography.bodyDefault.lineHeight};
        ${
            isSiteName(SITE_NAME.CARSAVER) &&
            css`
                background-color: #f2f2f2;
            `
        }
    }
  
    a {
        text-decoration: underline;
        color: ${({ theme: { palette } }) => palette.primary.main};
        line-height: 24px;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0px;
    }
  
    a:hover {
        text-decoration: underline;
    }
  
    address {
        display: block;
        font-style: normal;
    }
  
    h1, h2, h3, h4 {
        font-family: ${({ theme: { typography } }) => typography.fontFamily};
        color: ${({ theme: { palette } }) => palette.common.black};
    }

    h3 {
        font-size: 17px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 26px;
    }

    p, ol li, ul li {
        font-size: ${({ theme }) => theme.typography.fontSize}px;
        font-weight: normal;
        letter-spacing: ${({ theme }) => theme.typography.letterSpacing}px;
        line-height: 24px;
    }

    label {
        letter-spacing: ${({ theme }) => theme.typography.letterSpacing}px;
        span, p {
            font-size: ${({ theme }) => theme.forms.fontSize}px;
        }
    }

    h4 {
        font-size: ${({ theme }) => theme.typography.fontSize}px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
    }

    body, h1, h2, h3, h4, p, dl, dd, figure {
        margin: 0;
    }


    ul,
    ol {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    input,
    button,
    textarea,
    select {
        font: inherit;
    }


    /* width */
    ::-webkit-scrollbar {
        height: 5px;
        width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

export default GlobalStyles;
