import { hesselViewModels } from '../../../view-models';
import { ProductDetailsState } from './product-details.types';

type SelectedConfigurationType = {
    carType: string;
    fuelType: string;
    transmissionType: string;
    tractionWheels: string;
    lengthType: string;
    heightType: string;
    equipmentLine: string;
    batteryInformation: hesselViewModels.ElCarBatteryInfo;
};

export function getAvailableConfigurations(
    state?: Partial<Pick<ProductDetailsState, 'allConfigurations' | 'carFamily' | 'selectedProduct'>>
): hesselViewModels.CarConfiguration {
    if (state?.allConfigurations) {
        const allConfigurations = state.allConfigurations;
        const carFamily = state.carFamily ?? [];
        const elCarBatteryInfo =
            state?.selectedProduct?.fuelType === 'El'
                ? {
                      capacity: state?.selectedProduct.batteryCapacity ?? '',
                      range: state?.selectedProduct.range ?? 0,
                  }
                : undefined;

        const selected: SelectedConfigurationType = {
            carType: state?.selectedProduct?.carType ?? '',
            fuelType: state?.selectedProduct?.fuelType ?? '',
            transmissionType: state?.selectedProduct?.transmissionType ?? '',
            tractionWheels: state?.selectedProduct?.tractionWheels ?? '',
            lengthType: state?.selectedProduct?.lengthType ?? '',
            heightType: state?.selectedProduct?.heightType ?? '',
            equipmentLine: state?.selectedProduct?.equipmentLine ?? '',
            batteryInformation: elCarBatteryInfo ?? { capacity: '', range: -1 },
        };

        const availableCarTypes: Array<string> = [];
        const availableFuelTypes: Array<string> = [];
        const availableTransmissionTypes: Array<string> = [];
        const availableTractionWheels: Array<string> = [];
        const availableLengths: Array<string> = [];
        const availableHeigths: Array<string> = [];
        const availableEquipmentLines: Array<string> = [];
        const availableBatteryInfos: Array<hesselViewModels.ElCarBatteryInfo> = [];

        // Car type
        for (let index = 0; index < allConfigurations.carTypes.length; index++) {
            const cType = allConfigurations.carTypes[index];

            if (cType !== selected.carType) {
                const car = carFamily.find(
                    (x) =>
                        cType === x.carType &&
                        x.fuelType === selected.fuelType &&
                        x.transmissionType === selected.transmissionType &&
                        (x.vehicleType === 'Van' ? x.lengthType === selected.lengthType && x.heightType === selected.heightType : true) &&
                        x.tractionWheels === selected.tractionWheels &&
                        (x.brand !== 'Mercedes-Benz' ? evaluateEquipmentLine(selected, x) : true) &&
                        (x.fuelType === 'El' ? x.batteryCapacity === selected.batteryInformation.capacity : true)
                );

                if (car) {
                    availableCarTypes.push(cType);
                }
            }
        }

        // Fuel Type
        for (let index = 0; index < allConfigurations.fuelTypes.length; index++) {
            const fType = allConfigurations.fuelTypes[index];

            if (fType !== selected.fuelType) {
                const car = carFamily.find(
                    (x) =>
                        fType === x.fuelType &&
                        x.carType === selected.carType &&
                        x.transmissionType === selected.transmissionType &&
                        x.tractionWheels === selected.tractionWheels &&
                        (x.vehicleType === 'Van' ? x.lengthType === selected.lengthType && x.heightType === selected.heightType : true) &&
                        (x.brand !== 'Mercedes-Benz' ? evaluateEquipmentLine(selected, x) : true) &&
                        (x.fuelType === 'El' ? x.batteryCapacity === selected.batteryInformation.capacity : true)
                );

                if (car) {
                    availableFuelTypes.push(fType);
                }
            }
        }

        // Transmission Types
        for (let index = 0; index < allConfigurations.transmissionTypes.length; index++) {
            const tType = allConfigurations.transmissionTypes[index];

            if (tType !== selected.transmissionType) {
                const car = carFamily.find(
                    (x) =>
                        tType === x.transmissionType &&
                        x.carType === selected.carType &&
                        x.fuelType === selected.fuelType &&
                        x.tractionWheels === selected.tractionWheels &&
                        (x.vehicleType === 'Van' ? x.lengthType === selected.lengthType && x.heightType === selected.heightType : true) &&
                        (x.brand !== 'Mercedes-Benz' ? evaluateEquipmentLine(selected, x) : true) &&
                        (x.fuelType === 'El' ? x.batteryCapacity === selected.batteryInformation.capacity : true)
                );

                if (car) {
                    availableTransmissionTypes.push(tType);
                }
            }
        }

        // Traction Wheels
        for (let index = 0; index < allConfigurations.tractionWheels.length; index++) {
            const trWheels = allConfigurations.tractionWheels[index];

            if (trWheels !== selected.tractionWheels) {
                const car = carFamily.find(
                    (x) =>
                        trWheels === x.tractionWheels &&
                        x.carType === selected.carType &&
                        x.fuelType === selected.fuelType &&
                        x.transmissionType === selected.transmissionType &&
                        (x.vehicleType === 'Van' ? x.lengthType === selected.lengthType && x.heightType === selected.heightType : true) &&
                        (x.brand !== 'Mercedes-Benz' ? evaluateEquipmentLine(selected, x) : true) &&
                        (x.fuelType === 'El' ? x.batteryCapacity === selected.batteryInformation.capacity : true)
                );

                if (car) {
                    availableTractionWheels.push(trWheels);
                }
            }
        }

        // Lengths
        for (let index = 0; index < allConfigurations.lengthTypes.length; index++) {
            const lType = allConfigurations.lengthTypes[index];
            if (lType !== selected.lengthType) {
                const car = carFamily.find(
                    (x) =>
                        lType === x.lengthType &&
                        x.tractionWheels === selected.tractionWheels &&
                        x.carType === selected.carType &&
                        x.fuelType === selected.fuelType &&
                        x.transmissionType === selected.transmissionType &&
                        x.heightType === selected.heightType &&
                        (x.brand !== 'Mercedes-Benz' ? evaluateEquipmentLine(selected, x) : true) &&
                        (x.fuelType === 'El' ? x.batteryCapacity === selected.batteryInformation.capacity : true)
                );

                if (car) {
                    availableLengths.push(lType);
                }
            }
        }

        // Heights
        for (let index = 0; index < allConfigurations.heightTypes.length; index++) {
            const hType = allConfigurations.heightTypes[index];

            if (hType !== selected.heightType) {
                const car = carFamily.find(
                    (x) =>
                        hType === x.heightType &&
                        x.tractionWheels === selected.tractionWheels &&
                        x.carType === selected.carType &&
                        x.fuelType === selected.fuelType &&
                        x.transmissionType === selected.transmissionType &&
                        x.lengthType === selected.lengthType &&
                        (x.brand !== 'Mercedes-Benz' ? evaluateEquipmentLine(selected, x) : true) &&
                        (x.fuelType === 'El' ? x.batteryCapacity === selected.batteryInformation.capacity : true)
                );

                if (car) {
                    availableHeigths.push(hType);
                }
            }
        }

        // Equipment Line
        for (let index = 0; index < allConfigurations.equipmentLines.length; index++) {
            const eqLine = allConfigurations.equipmentLines[index];

            if (eqLine !== selected.equipmentLine) {
                const car = carFamily.find(
                    (x) =>
                        eqLine === x.equipmentLine &&
                        x.carType === selected.carType &&
                        x.fuelType === selected.fuelType &&
                        x.transmissionType === selected.transmissionType &&
                        (x.vehicleType === 'Van' ? x.lengthType === selected.lengthType && x.heightType === selected.heightType : true) &&
                        x.tractionWheels === selected.tractionWheels &&
                        (x.fuelType === 'El' ? x.batteryCapacity === selected.batteryInformation.capacity : true)
                );

                if (car) {
                    availableEquipmentLines.push(eqLine);
                }
            }
        }

        // Battery Capacity
        if (selected.fuelType === 'El' && selected.batteryInformation && allConfigurations.batteryInfoList) {
            for (let index = 0; index < allConfigurations.batteryInfoList.length; index++) {
                const batInfo = allConfigurations.batteryInfoList[index];

                if (batInfo.capacity !== selected.batteryInformation.capacity) {
                    const car = carFamily.find(
                        (x) =>
                            batInfo.capacity === x.batteryCapacity &&
                            batInfo.range === x.range &&
                            x.carType === selected.carType &&
                            x.fuelType === selected.fuelType &&
                            x.transmissionType === selected.transmissionType &&
                            x.tractionWheels === selected.tractionWheels &&
                            (x.vehicleType === 'Van' ? x.lengthType === selected.lengthType && x.heightType === selected.heightType : true) &&
                            (x.brand !== 'Mercedes-Benz' ? evaluateEquipmentLine(selected, x) : true)
                    );

                    if (car) {
                        availableBatteryInfos.push(batInfo);
                    }
                }
            }
        }

        return {
            carTypes: [...new Set([selected.carType, ...availableCarTypes])],
            fuelTypes: [...new Set([selected.fuelType, ...availableFuelTypes])],
            transmissionTypes: [...new Set([selected.transmissionType, ...availableTransmissionTypes])],
            tractionWheels: [...new Set([selected.tractionWheels, ...availableTractionWheels])],
            batteryInfoList: [...new Set([selected.batteryInformation, ...availableBatteryInfos])],
            lengthTypes: [...new Set([selected.lengthType, ...availableLengths])],
            heightTypes: [...new Set([selected.heightType, ...availableHeigths])],
            equipmentLines: [...new Set([selected.equipmentLine, ...availableEquipmentLines])],
        };
    }

    return {
        carTypes: [],
        fuelTypes: [],
        transmissionTypes: [],
        tractionWheels: [],
        batteryInfoList: [],
        lengthTypes: [],
        heightTypes: [],
        equipmentLines: [],
    };
}

const evaluateEquipmentLine = (selected: SelectedConfigurationType, x: hesselViewModels.ProductDetails): boolean => {
    if ((selected.equipmentLine === null || selected.equipmentLine === '') && (x.equipmentLine === null || x.equipmentLine === '')) return true;
    if (selected.equipmentLine === x.equipmentLine) return true;
    return false;
};
