import router from 'next/router';
import { sharedTypes } from '../../lib/api/models/umbraco';
import { cmsUrlWithoutSiteId } from './umbraco-content.helper';

export function handleUrlClick(url?: sharedTypes.CtaUrl, withReload?: boolean): void {
    if (!url) {
        router.push('/');
        return;
    }

    if (!!url.target && url.type === sharedTypes.UrlType.External) {
        window.open(url.url, '_blank');
    } else if (withReload) {
        window.open(url.url, '_self');
    } else {
        const urlWithoutCmsId = cmsUrlWithoutSiteId(url.url);
        if (url.target === '_blank') {
            window.open(urlWithoutCmsId, '_blank');
        } else {
            router.push(cmsUrlWithoutSiteId(urlWithoutCmsId));
        }
    }
}

export function isExternalLink(url?: sharedTypes.CtaUrl): boolean {
    if (!url) {
        return false;
    }

    return !!url.target && url.type === sharedTypes.UrlType.External;
}

type KVP = {
    key: string;
    value: string;
};

export const getQueryStringParams = (query: string): KVP[] => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params: KVP[], param) => {
              const [key, value] = param.split('=');
              const newKvp = {
                  key,
                  value: value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '',
              };
              params.push(newKvp);
              return params;
          }, [])
        : [];
};

export function storePathValues(): void {
    const storage = sessionStorage;
    if (!storage) return;
    // Set the previous path as the value of the current path.
    const prevPath = storage.getItem('currentPath');
    storage.setItem('prevPath', prevPath?.toLowerCase() ?? '');
    // Set the current path value by looking at the browser's location object.
    storage.setItem('currentPath', window.location.pathname.toLowerCase());
}

export function getPreviousPath(): string {
    const storage = sessionStorage;
    if (!storage) return '';
    const prevPath = storage.getItem('prevPath');
    return prevPath ?? '';
}

export type VehicleFilterQueryParams = {
    [key: string]: string | number;
};

export function appendParamsToUrl(url: string, queryParams: Array<VehicleFilterQueryParams>): string {
    const urlAlreadyHasQueryParameters = url.includes('?');

    if (urlAlreadyHasQueryParameters) {
        return url;
    }

    let hash = '';
    let baseUrl: string = url;

    const hashIndex: number = url.indexOf('#');
    if (hashIndex !== -1) {
        baseUrl = url.slice(0, hashIndex);
        hash = url.slice(hashIndex);
    }

    const queryString: string = queryParams.map((param: VehicleFilterQueryParams) => `${param.key}=${encodeURIComponent(param.value)}`).join('&');

    return `${baseUrl}?${queryString}${hash}`;
}

export function getProtocol(hostName: string): string {
    if (!hostName.startsWith('localhost')) return 'https://';
    return 'http://';
}
