import { hesselApi, umbraco } from '../../lib/api';

const vehicleServiceAgreement = (
    serviceAgreements: umbraco.Agreement[],
    vehicle?: Pick<hesselApi.VehicleInformation, 'serviceAgreement'>
): string => {
    return vehicle
        ? serviceAgreements.find(({ agreementID }) => agreementID === vehicle.serviceAgreement)?.agreementName ?? vehicle.serviceAgreement ?? ''
        : '';
};

export const customerAgreement = (
    content: Pick<umbraco.AgreementInfo, 'benefitAgreements' | 'serviceAgreements'>,
    agreements?: Pick<hesselApi.VehicleInformation, 'serviceAgreement'> & { benefitAgreement?: string },
    benefitAgreementName?: string
): string => {
    return [vehicleServiceAgreement(content.serviceAgreements, agreements), benefitAgreementName]
        .filter((agreement) => (agreement ? true : false))
        .join(' & ');
};
